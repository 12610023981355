
import { Http } from "@/http"
import { Id, res_list, req_list } from '@/types/global'

export interface add_meal{
    name:string,
    amount:string
    labels:string
    gifts:string
    discount:String,
    lifespan:Number,
}
export interface meal extends add_meal{
    id:Id,
    create_time:string
    update_time:string
}


class HttpSettings extends Http{
	get_list = (data:req_list) => {
		return this.get<res_list<meal>>({...data})
	}

    get_info = (id:number) => {
        return this.get<meal>({},`/admin/meal/${id}/`)
    }
    create = (data:add_meal) => {
        return this.post(data)
    }
    edit = ({id,...data}: meal) => {
        return this.patch(data,`/admin/meal/${id}/`)
    }
    del_one = (id:number) => {
        return this.delete(id)
    }
    get_config = ()=>{
        return this.get({},`/admin/meal/`)
    }
}

export const api_meal =  new HttpSettings('/admin/meal/')
