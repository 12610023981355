






























































































import { Vue, Component, Watch } from "vue-property-decorator";
import { api_meal, meal } from "@/http/meal";
import { api_goodsCategory, goodsCategory } from "@/http/goodsCategory";
import PageHeader from "@/components/pageHeader/index.vue";
import { ElForm } from "element-ui/types/form";
@Component({
  components: {
    PageHeader,
  },
})
export default class extends Vue {
  info: meal = {
    id: "",
    create_time: "",
    update_time: "",
    amount: "",
    labels: "",
    gifts: "",
    name: "",
    discount: "",
    lifespan: 0,
  };
  rules = {
    name: [{ required: true, message: "请输入会员卡名称" }],
    amount: [{ required: true, message: "请输入充值金额" }],
    labels: [{ required: true, message: "请填写优惠价" }],
    gifts: [{ required: true, message: "请填写赠品" }],
    lifespan: [{ required: true, message: "请填写有效天数" }],
  };

  async submit() {
    await (this.$refs["form"] as ElForm | undefined)?.validate();
    const categories = this.categories.map((category) => ({
      id: category.id,
      rebate: category.rebate,
      name: category.name,
    }));
    this.info.discount = JSON.stringify(categories);
    if (this.id) {
      await api_meal.edit(this.info);
    } else {
      await api_meal.create(this.info);
    }
    this.$router.go(-1);
  }

  async get_info() {
    this.categories = [];
    try {
      this.info = await api_meal.get_info(this.id as number);
      this.$nextTick(() => {
        if (this.info && this.info.discount) {
        this.categories = JSON.parse(this.info.discount as string);
      } else {
        this.categories = [];
      }
      });
    } catch (error) {
      console.error("Error fetching meal info:", error);
      this.categories = [];
    }
  }
  get id(): undefined | number {
    return this.$route.query.id as any;
  }

  // 处理分类折扣输入
  validateInput(event: any, category: any) {
    let value = event;
    if (value === "") {
      category.rebate = "";
    } else {
      value = parseFloat(value);
      if (isNaN(value)) {
        event = "";
      } else {
        category.rebate = Math.max(0, Math.min(1, value));
      }
    }
  }
  categories = [] as any[];
  // 获取分类列表
  async get_category_list() {
    const res = await api_goodsCategory.get_list({ page_size: 10000 });
    this.categories = res.results;
  }
  created() {
    if (this.id === undefined){
      this.get_category_list();
      return;
    }
    this.get_info();
  }
}
